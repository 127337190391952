import * as React from 'react';

function ResponsiveVideo({ videoLink, title }) {
  return (
    <div className="relative overflow-hidden xl:w-[1114px] lg:w-full mobile:w-full pt-[56.25%]">
      <iframe
        className="absolute top-0 right-0 bottom-0 left-0 w-full h-full"
        src={videoLink}
        title={title}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
}

export default ResponsiveVideo;
