import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../../components/layout/layout';
import Section0 from '../../page-components/opportunitydetail/section0';

import science from '../../images/headerOpportunities.svg';

function OpportunityDetail({ data, location }) {
  const opportunityTitle = data.opportunity.title;
  const headerContent = {
    heading: 'Opportunities',
    subheading:
      'Every year, billions of dollars in health investments is available for innovators in the Federal Health IT space. This is where you can join learn more, collaborate, and join the conversation about these health innovations and investments.',
    image: science,
    alt: 'Opportunities Abound',
  };

  return (
    <Layout
      title={opportunityTitle}
      headerContent={headerContent}
      location={location}
    >
      <Section0 opportunity={data.opportunity} />
    </Layout>
  );
}

export default OpportunityDetail;

export const pageQuery = graphql`
  query ($id: String) {
    opportunity: contentfulOpportunity(id: { eq: $id }) {
      id
      title
      body {
        childMarkdownRemark {
          html
        }
      }
      solicitationNumber
      value
      type
      release
      category {
        category
      }
      image {
        title
        description
        file {
          url
        }
      }
      relatedDocuments {
        title
        file {
          fileName
          url
        }
      }
    }
  }
`;
