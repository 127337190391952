import * as React from 'react';

import fileIcon from '../../../../images/file_icon.png';

function RelatedDocuments({ documents }) {
  const numberOfColumns = 3;
  const chunkedDocuments = documents.reduce(
    (resultArray, document, idx) => {
      const chunkIndex = idx % numberOfColumns;

      resultArray[chunkIndex].push(document);

      return resultArray;
    },
    [[], [], []]
  );

  return (
    <div
      className="
            grid
            grid-cols-3
            lg:gap-[50px]
            mobile:grid-cols-1
            w-full
            smMobile:w-full
            mobile:mx-auto
        "
    >
      {chunkedDocuments.map((chunk) => {
        return (
          <div
            className={`
              w-[275px]
              mobile:w-full
            `}
            key={chunk[0].title}
          >
            {chunk.map((document) => (
              <div
                className="
                                            flex
                                            items-center
                                            hover:cursor-pointer
                                            min-h-[90px]
                                            mobile:min-h-[75px]
                                        "
                key={document.file.url}
              >
                <img
                  className="
                                                mr-[18px]
                                                h-[24px]
                                            "
                  src={fileIcon}
                  alt={document.title}
                />
                <a
                  className="
                                                font-roboto
                                                text-gray
                                                text-14
                                                leading-[30px]
                                                hover:underline
                                            "
                  href={document.file.url}
                  download={document.file.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {document.file.fileName}
                </a>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
}

export default RelatedDocuments;
