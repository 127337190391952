/* eslint-disable no-restricted-globals */

import * as React from 'react';
import Opportunity from '../../components/opportunities/components/opportunity/opportunity';
import ResponsiveVideo from '../../components/responsive-video/responsive-video';
import RelatedDocuments from './components/related-documents/related-documents';

import { btnUnderlineHover } from './section-0.module.css';

function Section0({ opportunity }) {
  return (
    <section
      className={`
      w-full
      p-[25px]
      pb-[120px]
      mobile:px-[15px]
      mobile:w-full
      mobile:mx-auto
    `}
    >
      <div
        className="
                xl:w-[1199px]
                lg:w-[890px]
                mobile:w-[428px]
                mx-auto
                mt-[35px]
                mobile:mx-[25px]
            "
      >
        <button
          type="button"
          onClick={() => history.back()}
          className={`
                        ${btnUnderlineHover}
                        font-roboto
                        text-orange
                        text-18
                        mobile:text-17
                        leading-[30px]
                    `}
        >
          &lt;&lt; Go Back
        </button>
      </div>
      <Opportunity opportunity={opportunity} isDetailPage />
      <div
        className="
        xl:w-[1199px]
        lg:w-[920px]
        mobile:w-full
        mx-auto
      "
      >
        <div className="mobile:hidden">
          {opportunity.video ? (
            <ResponsiveVideo
              videoLink={opportunity.video}
              title={`Video for ${opportunity.title}`}
            />
          ) : (
            ''
          )}
          {opportunity.image ? (
            <img
              className="
                                xl:w-[1150px]
                                lg:w-[835px]
                                h-auto
                                block
                                mx-auto
                                mb-[70px]
                            "
              src={opportunity.image.file.url}
              alt={opportunity.image.description}
            />
          ) : (
            ''
          )}
        </div>
        <div
          className="
                  mb-[35px]
                  xl:pl-[25px]
                  lg:pl-[40px]
                  mobile:pl-[25px]
              "
        >
          <h2
            className="
                          font-roboto
                          text-neutral-500
                          font-medium
                          text-32
                          leading-[35px]
                          mb-[35px]
                          mobile:mt-[55px]
                          mobile:text-24
                          mobile:mx-auto
                      "
          >
            Related Documents
          </h2>
          <div>
            {opportunity.relatedDocuments?.length > 0 ? (
              <RelatedDocuments documents={opportunity.relatedDocuments} />
            ) : (
              <h3
                className="
                                    font-roboto
                                    text-14
                                    text-neutral-400
                                    leading-[30px]
                                    mobile:ml-[25px]
                                "
              >
                No documents available
              </h3>
            )}
          </div>
        </div>
        <div className="lg:hidden mobile:block">
          {opportunity.video ? (
            <ResponsiveVideo
              videoLink={opportunity.video}
              title={`Video for ${opportunity.title}`}
            />
          ) : (
            ''
          )}
          {opportunity.image ? (
            <img
              className="
                                w-[320px]
                                h-auto
                                block
                                mx-auto
                                pt-[33px]
                            "
              src={opportunity.image.file.url}
              alt={opportunity.image.description}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </section>
  );
}

export default Section0;
